const state = {
    // 登录成功获取的信息
    loginInfos: window.localStorage.getItem('loginInfos') ? JSON.parse(window.localStorage.getItem('loginInfos')) : '',
    // 用户个人资料
    userInfos: window.localStorage.getItem('userInfos') ? JSON.parse(window.localStorage.getItem('userInfos')) : '',
    // 系统配置
    sysConfig: window.localStorage.getItem('sysConfig') ? JSON.parse(window.localStorage.getItem('sysConfig')) : {},
    // 公共配置
    publicConfig: window.localStorage.getItem('publicConfig') ? JSON.parse(window.localStorage.getItem('publicConfig')) : {},
    // 提交卖币成功后
    sellInfos: window.localStorage.getItem('sellInfos') ? JSON.parse(window.localStorage.getItem('sellInfos')) : {
        avatar: '',
        pay_type: '',
        type: '',
        currency: '',
        id:'',
        info_status: 1, // 1是新增 2是修改 3是删除
        status: 1
    },
    // 新订单提醒
    orderRemind: window.localStorage.getItem('haveNewOrder') ? JSON.parse(window.localStorage.getItem('sellInfos')) : {
        order_sn: '',
        type: ''
    },
    // 未处理订单数
    untreatedNum: 0,
    // 卖家确认打币后，ws推送的买卖双方金额变化
    moneyChangeInfos: window.localStorage.getItem('moneyChangeInfos') ? JSON.parse(window.localStorage.getItem('moneyChangeInfos')) : {
        currency: '',
        sell_currency: '',
        freeze_currency: ''
    },
    // 已实名
    isName: false,
    // 用户金额
    memberAmountObj:  window.localStorage.getItem('memberAmountObj') ? JSON.parse(window.localStorage.getItem('memberAmountObj')) : {
        currency_money: '',
        sell_money: '',
        trade_money: ''
    },
    // 主题对应的图片，默认为黑色皮肤
    themeImgs: window.localStorage.getItem('themeImgs') ? window.localStorage.getItem('themeImgs') : 'black',
    // 默认皮肤为黑金色
    theme: window.localStorage.getItem('theme') ? window.localStorage.getItem('theme') : 'black',
    // 从综合传递过来的真实姓名
    actualName: window.sessionStorage.getItem('actualName') ? window.sessionStorage.getItem('actualName') : ''
}

export default state
