<template>
<div>
    <router-view />
    <!-- 全局消息提醒 -->
    <!-- <van-dialog
        v-model:show="isHaveNewOrder"
        class="global-dialog"
        :show-confirm-button="false"
    >
        <div class="close-btn" @click="isHaveNewOrder=false"></div>
        <p>你有新的订单状态，请立即处理</p>
        <div class="comfirm-btn-box">
            <van-button block :disabled="btnClock" @click="handleComfirm">确认</van-button>
        </div>
    </van-dialog> -->
    <!-- 扫码支付密码弹框 -->
    <van-dialog v-model:show="showCodePayDialog" title="付款" show-cancel-button @confirm="handleCodePay">
        <van-field v-model="codePayPassWordValue" type="password" label="支付密码" placeholder="请输入支付密码" autocomplete="off" />
    </van-dialog>
  <audio ref="myAudio" :src="require('./assets/mp3/orderRemind.mp3')" muted />
</div>
</template>

<script>
import { ref, onBeforeMount, computed, watch, onMounted } from "vue";
import wsConnection from "@/utils/websocket";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { codePay,getSysConfig,getPublicConfig, getOrderRemind } from '@/api/api'
import { Toast } from 'vant'

export default {
    setup() {
        const $route = useRoute();
        const $router = useRouter();
        const store = useStore();
        const myAudio = ref()

        const siteTitleObj = {
          "agpay": "AGPAY",
          "aipay": "AIPAY"
        }
        onMounted(() => {
            document.getElementById("siteTitle").innerText = siteTitleObj[siteChannel];
            document.getElementById("favicon").href = require('@siteResources/logo/favicon.png');
            window.addEventListener("resize", forceLandscapeScreenHandle)
            forceLandscapeScreenHandle()

          // 在safari中必须先触发一次play之后才能在代码中被动播放
          const callBack = ()=>{
            playAudio()

            let timer = setTimeout(()=>{
              myAudio.value.pause()
              myAudio.value.muted = false
              myAudio.value.currentTime = 0
              clearTimeout(timer)
              document.removeEventListener('click', callBack)
            },1)
          }
          document.addEventListener('click',callBack)
        })

        onBeforeMount(() => {
            ifLogin()
            if (store.state.loginInfos.token) {
                wsConnection.initWebSocket()
                handleGetSysConfig()
                handleGetOrderRemind()
            }
            // setTheme()
            handleGetPublicConfig()
        })

      const playAudio = () => {
        myAudio.value.play()
      }

      // 获取订单状态提醒
      const handleGetOrderRemind = () => {
        getOrderRemind().then(res => {
          if(res.result === 200000) {
            if(res.data.count > 0) {
              store.commit('setUntreatedNum', res.data.count)
            } else {
              store.commit('setUntreatedNum', 0)
            }
          }
        })
      }

        const forceLandscapeScreenHandle = () => {
            const body = document.getElementsByTagName('body')[0];
            const html = document.getElementsByTagName('html')[0];
            const width = html.clientWidth;
            const height = html.clientHeight;
            const max = width > height ? width : height;
            const min = width > height ? height : width;
            body.style.width = max + "px";
            body.style.height = min + "px";
        }
        const ifLogin = () => {
            // 监听从综合iframe那过来的参数
            // 解密
            if(window.parent.frames['agIframe'] && window.location.href.indexOf('token') == -1) {
                if (getUrlParams(location.href)['query']) {
                    let data = JSON.parse(decodeURIComponent(getUrlParams(location.href)['agInfos']))
                    let actualName = decodeURIComponent(getUrlParams(location.href)['actualName'])
                    store.dispatch("saveLoginInfosActions", data)
                    store.dispatch('saveActualNameActions', actualName)
                    let order = JSON.parse(decodeURIComponent(getUrlParams(location.href)['query']))
                    if(order.type==1) {
                        $router.push({
                            path: 'buyerOrderDetails',
                            query: {
                                order_sn: order.order_sn
                            }
                        })
                    } else {
                        $router.push({
                            path: 'sellerOrderDetails',
                            query: {
                                order_sn: order.order_sn
                            }
                        })
                    }
                } else {
                    let data = JSON.parse(decodeURIComponent(getUrlParams(location.href)['agInfos']))
                    let actualName = decodeURIComponent(getUrlParams(location.href)['actualName'])
                    store.dispatch("saveLoginInfosActions", data);
                    store.dispatch('saveActualNameActions', actualName)
                }
            } else if(window.location.href.indexOf('token') != -1 && window.location.href.indexOf('three_username') == -1) {
                // （iframe嵌套）从其他第三方平台过来的，通过https://antpay-h5test.mayibet.site/#/home?token=123&terminal=1 这样的方式能够进行免密登录
                let data = getUrlParams(location.href)['token']
                store.dispatch("saveLoginInfosActions", {token:data});
                $router.push('/home')
                localStorage.setItem('isFromThird', true)
            }
            // 从其他第三方平台过来的，通过https://antpay-h5test.mayibet.site/#/home?token=123&terminal=1 这样的方式能够进行免密登录
            // if (!window.parent.frames['agIframe'] && window.location.href.indexOf('token') != -1 && window.location.href.indexOf('three_username') == -1 ) {
            //     let data = getUrlParams(location.href)['token']
            //     store.dispatch("saveLoginInfosActions", {token:data});
            //     $router.push('/home')
            //     localStorage.setItem('isFromThird', true)
            // }
        }
        // 获取url参数
        const getUrlParams = (url) => {
            let urlStr = url.split('?')[1]
            const urlSearchParams = new URLSearchParams(urlStr)
            const result = Object.fromEntries(urlSearchParams.entries())
            return result
        }
        // 换肤
        const setTheme = () => {
            let theme = 'black'
            // siteTheme 主题颜色，是打包时指定的全局变量
            const paramsTheme = getUrlParams(location.href)['agTheme'] || siteTheme
            if (['black','blue','golden','red','green'].includes(paramsTheme)) {
                theme = paramsTheme
            }
            import(`@/assets/theme/${theme}/index.js`).then((res) => {
                store.dispatch('saveThemeImgsActions', res.default)
                const $body = document.querySelector('body');
                $body.className = `${theme}`;
                if (theme == 'black') {
                    $body.style.color = '#fff';
                    $body.style.background = '#242424';
                } else {
                    $body.style.color = '#fff';
                }
            })
        }
        // 获取系统配置
        const handleGetSysConfig = () => {
            getSysConfig().then(res => {
                if(res.result == 200000) {
                    store.dispatch('saveSysConfigActions', res.data.data)
                }
            })
        }
        // 获取公共配置
        const handleGetPublicConfig = () => {
            getPublicConfig().then(res => {
                if(res.result == 200000) {
                    store.dispatch('savePuclicConfigActions', res.data.list)
                }
            })
        }
        // 是否显示有无订单状态
        const isHaveNewOrder = ref(false);
        // 存储订单状态提醒数据
        const orderRemind = ref([]);
        // 计算属性实现视图更新
        const orderRemindObj = computed(() => store.state.orderRemind);
        const themeChange = computed(() => store.state.theme);
        watch(
            orderRemindObj,
            (newValue) => {
              // 计算属性实现视图更新--新订单提醒
              handleGetOrderRemind()
              // 消息语音提示
              playAudio()
              isHaveNewOrder.value = true;
              orderRemind.value = newValue;
            },
            {
                deep: true, // name是一个对象，需要进行深度监听
            }
        );
        watch(
            themeChange,
            (newValue) => {
                handleSetThemeVars()
            },
            {
                deep: true, // name是一个对象，需要进行深度监听
            }
        );
        const handleComfirm = () => {
            let id = 0;
            if (orderRemind.value.type == 1) {
                if ($route.query.order_sn == orderRemind.value.order_sn) {
                    $router.push({
                        path: "buyerOrderDetails",
                        query: {
                            order_sn: orderRemind.value.order_sn,
                            id: id++,
                        },
                    });
                } else {
                    $router.push({
                        path: "buyerOrderDetails",
                        query: {
                            order_sn: orderRemind.value.order_sn,
                        },
                    });
                }
            } else {
                if ($route.query.order_sn == orderRemind.value.order_sn) {
                    $router.push({
                        path: "sellerOrderDetails",
                        query: {
                            order_sn: orderRemind.value.order_sn,
                            id: id++,
                        },
                    });
                } else {
                    $router.push({
                        path: "sellerOrderDetails",
                        query: {
                            order_sn: orderRemind.value.order_sn,
                        },
                    });
                }
            }
        };
        // 是否显示支付密码框
        const showCodePayDialog = ref(false)
        // 暴露传递给app的函数
        window["handleOpenCodePayDialog"] = (order) => {
          if(order) {
            if (typeof order === 'string') {
              handleOpenCodePayDialog(JSON.parse(order))
              return
            }
            handleOpenCodePayDialog(order)
          }
        }
        // 存储app传递来的数据
        const ordersn = ref('')
        // 打开支付密码弹框
        const handleOpenCodePayDialog = (order) => {
            codePayPassWordValue.value = ''
            ordersn.value = order.order
            showCodePayDialog.value = true
        }
        // 存储支付密码
        const codePayPassWordValue = ref('')
        // 确认支付
        const handleCodePay = () => {
            codePay({order_sn:ordersn.value,pay_password:codePayPassWordValue.value}).then(res => {
                if(res.result == 200000) {
                    Toast.success('支付成功')
                    showCodePayDialog.value = false
                    ordersn.value = ''
                } else {
                    Toast.fail(res.msg)
                }
            })
        }

        return {
            isHaveNewOrder,
            handleComfirm,
            showCodePayDialog,
            codePayPassWordValue,
            handleCodePay,
            myAudio
        };
    },
};
</script>

<style lang="less">
// :deep(.global-dialog) {
//     width: 322px;
//     height: 210px;
//     top: 50%;
//     padding-top: 70px;
//     background: url('./assets/images/common/tips-bg.png') no-repeat;
//     background-size: 100% 100%;
//     border-radius: 3px;
//     .close-btn {
//         position: absolute;
//         top: 15px;
//         right: 0;
//         width: 28px;
//         height: 28px;
//         z-index: 1;
//     }
//     p {
//         padding: 20px 0;
//         text-align: center;
//         color: #fff;
//     }
//     .comfirm-btn-box {
//         margin-top: 33px;
//         .van-button {
//             width: 216px;
//             height: 41px;
//             margin: auto;
//             background: url('./assets/images/common/btn-bg.png') no-repeat;
//             background-size: 100% 100%;
//             border-radius: 8px;
//         }
//     }
// }
</style>
